<template>
    <div style="padding-bottom: 80px;">
        <div v-if="userId && initialLoading" class="admin-page-loading">{{ $t("loading.loading") }}</div>
        <div v-show="!userId || !initialLoading" class='text-left'>
            <h1 v-if="userId == loggedId" class="users-title"><span class="arrow arrow--left" @click='$router.go(-1)'></span>{{ $t("admin.myProfile") }}</h1>
            <h1 v-else class="users-title"><span class="arrow arrow--left" @click='$router.go(-1)'></span>{{user ? user.userName : $t("admin.addUser")}}</h1>
            <p v-if="user && user.blocked" class="user-state-alert">{{ $t("admin.userIsBlocked") }}</p>
            <p v-if="user && user.locked" class="user-state-alert">{{ $t("admin.userIsAnonymized") }}</p>
            <b-alert :show="alert.dismissCountDown" fade id="alert"
                    @dismiss-count-down="countDownChanged"
                    class="alert-success">{{alert.notification}}
            </b-alert>
            <b-alert :show="alertDanger.dismissCountDown" fade id="alert-danger"
                    @dismiss-count-down="countDownChangedDanger"
                    class="alert-danger">{{alertDanger.notification}}
                    <div class="x dismiss-x" @click="() => {alertDanger.dismissCountDown = 0}"></div>
            </b-alert>
            <form class="form d-flex flex-column" :class="{'no-interaction': !isEditingAllowed}" @submit.prevent="onSubmit" autocomplete="off" novalidate>
                <h3 class="section-title">{{ $t("general.basicInfo") }}</h3>

                <!-- Preferred Language -->
                <div class="form-group" v-if="userId == currentUserId">
                    <TrekioSelect v-model="preferredLanguage" :label="$t('inputLabels.language')" :text="preferredLanguage ? preferredLanguage : $t('admin.notSelected')">
                        <TrekioSelectOption v-for="language in $store.state.publicLanguageCodes" :key="language" :value="language">{{ language }}</TrekioSelectOption>
                    </TrekioSelect>
                </div>

                <!-- Role -->
                <div class="form-group" v-if="currentUserRole == 'ADMIN_SUPERVISOR'">
                    <TrekioSelect :label="$t('inputLabels.role')" v-model="form.role" :error="error.role" :text="form.role ? form.role : $t('admin.notSelected')">
                        <TrekioSelectOption v-for="role in roles" :key="role.code" :value="role.code">{{ role.code }}</TrekioSelectOption>
                    </TrekioSelect>

                    <div class="licence-signed-container" v-if="role == 'AUTHOR'">
                        <TrekioButton v-if="!licenceAgreementSignedAt" :isLoading="loadingLicence != ''" :loadingText="loadingLicence" type="button" primary @click="setHasLicenceAgreementSigned">{{ $t("buttons.authorSignedContract") }}</TrekioButton>
                        <div v-else class="input-container">
                            <label for="licenceSignedAt" class="placeholder-label">{{ $t("inputLabels.contractSigned") }}</label>
                            <input id="licenceSignedAt" disabled type="text"  autocomplete="off" v-model.trim="licenceAgreementSignedAt" maxlength="50">
                        </div>
                    </div>
                </div>

                <!-- Email, Username -->
                <div class="form-group">
                    <TrekioInput type="email" id="userLogin" trim v-model="form.userLogin" :label="$t('inputLabels.login')" :error="error.userLogin" maxlength="50" required
                        :info="$t('info.email')" :disabled="!isEditingAllowed"
                    />
                    <TrekioInput id="userName" trim v-model="form.userName" :label="$t('inputLabels.username')" :error="error.userName" maxlength="100" required
                        :info="$t('info.userNameAuthor')" :disabled="!isEditingAllowed"
                    />
                </div>

                <!-- Password, RepeatPassword -->
                <div class="form-group">
                    <TrekioInput type="password" id="password" v-model="form.password" :label="$t('inputLabels.password')" maxLength="256" autocomplete="new-password"
                        :error="error.password" :info="$t('info.password')" :disabled="!isEditingAllowed"
                    />
                    <TrekioInput type="password" id="repeatPassword" v-model="form.repeatPassword" :label="$t('inputLabels.repeatPassword')" maxLength="256" autocomplete="new-password"
                        :error="error.repeatPassword" :disabled="!isEditingAllowed"
                    />
                </div>

                <!-- FirstName, LastName -->
                <div class="form-group">
                    <TrekioInput id="firstName" trim v-model="form.firstName" :label="$t('inputLabels.firstName')" :error="error.firstName" maxlength="50"
                        :info="$t('info.firstNameAuthor')" :required="role === 'AUTHOR'" :disabled="!isEditingAllowed"
                    />
                    <TrekioInput id="lastName" trim v-model="form.lastName" :label="$t('inputLabels.lastName')" :error="error.lastName" maxlength="50"
                        :info="$t('info.lastNameAuthor')" :required="role === 'AUTHOR'" :disabled="!isEditingAllowed"
                    />
                </div>

                <!-- THIS SECTION IS ONLY FOR AUTHOR ROLE -->
                <fieldset class="author-section" v-if="form.role == 'AUTHOR'">
                    <!-- DateOfBirth, PhoneNumber -->
                    <div class="form-group">
                        <TrekioInput type="date" id="dateOfBirth" trim v-model="form.dateOfBirth" :label="$t('inputLabels.dateOfBirth')" :error="error.dateOfBirth" placeholder=" " maxlength="50"
                            :info="$t('info.dateOfBirth')" :disabled="!isEditingAllowed || isCurrentUserAuthor"
                        />
                        <TrekioInput id="phoneNumber" trim v-model="form.phoneNumber" :label="$t('inputLabels.phoneNumber')" min="0" maxlength="15"
                            :info="$t('info.phoneNumber')" @keypress="(e) => blockInvalidChar(e, true)" :disabled="!isEditingAllowed"
                        />
                    </div>

                    <div class="d-flex align-items-baseline">
                        <h3 class="section-title">{{ $t("general.address") }}</h3>
                        <div class="info-icon ml-2" v-tooltip.right-start="$t('info.address')"></div>
                    </div>

                    <!-- Country, City -->
                    <div class="form-group">
                        <TrekioSelect :label="$t('inputLabels.countries')" v-model="form.address.countryId" :disabled="isCurrentUserAuthor" :text="chosenCountry ? chosenCountry[countryLocalization] : $t('admin.notSelected')"
                            searchInput @searchInput="value => countriesSearchString = value" :searchString=countriesSearchString
                        >
                            <TrekioSelectOption v-for="country in filteredCountries" :key="country.code" :value="country.id">{{ country[countryLocalization] }}</TrekioSelectOption>
                        </TrekioSelect>
                        <TrekioInput id="city" trim v-model="form.address.city" :label="$t('inputLabels.city')" maxlength="100" :disabled="!isEditingAllowed || isCurrentUserAuthor"
                            :error="error.city"
                        />
                    </div>

                    <!-- Street, HouseNumber -->
                    <div class="form-group">
                        <TrekioInput id="street" trim v-model="form.address.street" :label="$t('inputLabels.street')" maxlength="100"
                            :error="error.street" :disabled="!isEditingAllowed || isCurrentUserAuthor"
                        />
                        <TrekioInput id="houseNumber" trim v-model="form.address.houseNumber" :label="$t('inputLabels.houseNumber')" maxlength="25"
                            :error="error.houseNumber" @keypress="blockInvalidChar" :disabled="!isEditingAllowed || isCurrentUserAuthor"
                        />
                    </div>

                    <!-- ZipCode, AdditionalInfo -->
                    <div class="form-group">
                        <TrekioInput id="zipCode" trim v-model="form.address.zipCode" :label="$t('inputLabels.zipCode')" maxlength="15"
                            :error="error.zipCode" :disabled="!isEditingAllowed || isCurrentUserAuthor"
                        />
                        <TrekioInput id="additionalInfo" trim v-model="form.address.additionalInfo" :label="$t('inputLabels.additionalInfo')" maxlength="4096"
                            :error="error.additionalInfo" :disabled="!isEditingAllowed || isCurrentUserAuthor"
                        />
                    </div>

                    <div class="d-flex align-items-baseline">
                        <h3 class="section-title">{{ $t("admin.aboutAuthor") }}</h3>
                        <div class="info-icon ml-2" v-tooltip.right-start="$t('info.aboutAuthor')"></div>
                    </div>

                    <div class="form-group">
                        <TrekioTextarea id="about" trim v-model="form.about" :label="$t('inputLabels.aboutAuthor')" :disabled="!isEditingAllowed"/>
                    </div>
                    <h5 class="mb-3">{{ $t("admin.doYouWantToBePublishedAsAuthor") }}</h5>
                    <div class="form-group">
                        <TrekioSwitch v-if="currentUserRole != 'ADMIN'" v-model="isAuthorPublished" :isLoading="isSwitchLoading" :activeText="$t('admin.authorIsPublished')" :inactiveText="$t('admin.authorIsHidden')"/>
                    </div>
                </fieldset>

                <div class="form-group img-container" :class="{'noborder': avatarFileName || chosenAvatar}">
                    <div v-if="avatarFileName" class="avatar-image" v-bind:style="{ backgroundImage: 'url(' + imgURL + avatarFileName.slice(0,avatarFileName.lastIndexOf('.')) + '-dimensions-640pxX480px' + avatarFileName.slice(avatarFileName.lastIndexOf('.')) +')'}">
                        <div class="x ml-auto" style="margin: 10px 10px 0 0; cursor: pointer;" @click="removeAvatar"></div>
                    </div>
                    <div v-else-if="chosenImgUrl" class="avatar-image" v-bind:style="{ backgroundImage: 'url(' + chosenImgUrl + ')'}">
                        <div class="x ml-auto" style="margin: 10px 10px 0 0; cursor: pointer;" @click="removeAvatar"></div>                
                    </div>
                    <div v-else class="d-flex align-items-center justify-content-center" style="width: 100%; height: 100%">
                        <label for="avatar"></label>
                        <input type="file" id="avatar" name="avatar" accept="image/png, image/jpeg" @change="setAvatar">
                        <div>
                            <div class="plus mx-auto"></div>
                            <p class="mt-4 text-center">{{ $t("admin.addProfilePicture") }}</p>
                        </div>
                    </div>
                </div>

                <div class="fixed-buttons" :class="{'full-width': !isSidebarVisible}">
                    <div>
                        <TrekioButton v-if="user && !user.locked && currentUserRole == 'ADMIN_SUPERVISOR' || user && ['AUTHOR', 'ADMIN'].includes(currentUserRole) && user.id == currentUserId" type="button" secondary @click="$bvModal.show('user-delete-modal')" >{{ $t("buttons.anonymize") }}</TrekioButton>
                        <TrekioButton v-if="user && !user.blocked && !user.locked && ['ADMIN', 'ADMIN_SUPERVISOR'].includes(currentUserRole)" type="button" secondary @click="$bvModal.show('user-block-modal')">{{ $t("buttons.block") }}</TrekioButton>
                        <TrekioButton v-else-if="['ADMIN', 'ADMIN_SUPERVISOR'].includes(currentUserRole) && user && !user.locked" type="button" secondary @click="$bvModal.show('user-unblock-modal')">{{ $t("buttons.unblock") }}</TrekioButton>
                        <TrekioButton v-if="currentUserRole == 'ADMIN_SUPERVISOR' || user && ['AUTHOR', 'ADMIN'].includes(currentUserRole) && user.id == currentUserId" type="submit"
                            :isLoading="loading != ''" :loadingText="loading"
                        >{{ $t("buttons.save") }}
                        </TrekioButton>
                    </div>
                </div>
            </form>
            <b-modal class="modal" id="user-delete-modal" hide-footer hide-header>
                <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto">
                    <h3 class="moda-title pb-3">{{ $t("modals.userAnonymizeTitle", [user && user.userLogin]) }}</h3>
                    <h4 class="mb-3 font-weight-bold" v-if="userId === loggedId">{{ $t("modals.userAnonymizeAlert") }}</h4>
                    <p v-if="this.userId === loggedId" class="modal-text">{{ $t("modals.userAnonymizeText") }}</p>
                    <p v-else class="moda-text pb-5">{{ $t("modals.userAnonymizeOtherText") }}</p>
                    <p v-if="this.userId === loggedId" class="modal-text">{{ $t("modals.userAnonymizeText2") }}</p>
                    <div class="d-flex mt-5" style="gap: 40px">
                        <TrekioButton secondary @click="$bvModal.hide('user-delete-modal')">{{$t("general.back")}}</TrekioButton>
                        <TrekioButton primary :isLoading="loadingDelete != ''" :loadingText="loadingDelete" @click="onDeleteConfirmed">{{ $t("buttons.anonymize") }}</TrekioButton>
                    </div>
                </div>
            </b-modal>
            <b-modal class="modal" id="user-block-modal" hide-footer hide-header>
                <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto">
                    <h3 class="moda-title pb-3">{{ $t("modals.userBlockTitle", [user && user.userLogin]) }}</h3>
                    <p class="moda-text pb-5">{{ $t("modals.userBlockText") }}</p>
                    <div class="d-flex" style="gap: 40px">
                        <TrekioButton secondary @click="$bvModal.hide('user-block-modal')">{{$t("general.back")}}</TrekioButton>
                        <TrekioButton primary :isLoading="loadingBlock != ''" :loadingText="loadingBlock" @click="onBlockConfirmed">{{ $t("buttons.block") }}</TrekioButton>
                    </div>
                </div>
            </b-modal>
            <b-modal class="modal" id="user-unblock-modal" hide-footer hide-header>
                <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto">
                    <h3 class="moda-title pb-3">{{ $t("modals.userUnblockTitle", [user && user.userLogin]) }}</h3>
                    <p class="moda-text pb-5">{{ $t("modals.userUnblockText") }}</p>
                    <div class="d-flex" style="gap: 40px">
                        <TrekioButton secondary @click="$bvModal.hide('user-unblock-modal')">{{$t("general.back")}}</TrekioButton>
                        <TrekioButton primary :isLoading="loadingUnblock != ''" :loadingText="loadingUnblock" @click="onUnblockConfirmed">{{ $t("buttons.unblock") }}</TrekioButton>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
    import UserService from "@/services/UserService";
    import {AUTHOR_BY_ID, USER_PREFERRED_LANGUAGE} from "@/api/graphql/query/UserQuery";
    import {ROLES} from "@/api/graphql/query/UserRoleQuery";
    import {COUNTRIES} from "@/api/graphql/query/CountryQuery";
    import {CLEVERTRIP_API_URL}  from '@/definitions';
    import fileUtils from "../../utils/fileUtils";
    import sharedUtils from "../../utils/sharedUtils";
    import { userInfo } from "../../components/InfoIconTexts";

    export default {
        name: "ManageUserDlg",

        data: function() {
            return {
                userInfo: userInfo,
                initialLoading: true,
                isEditingAllowed: true,
                loading: '',
                loadingDelete: '',
                loadingBlock: '',
                loadingUnblock: '',
                loadingLicence: '',
                imgURL: CLEVERTRIP_API_URL + "/avatar/downloadFile/",
                loggedId: localStorage.getItem('id'),
                loggedRole: localStorage.getItem('role'),
                userId: null,
                licenceAgreementSignedAt: '',
                isSwitchLoading: false,
                isAuthorPublished: null,
                role: null,
                form: {
                    userName: '',
                    userLogin: '',
                    phoneNumber: null,
                    password: '',
                    role: null,
                    repeatPassword: '',
                    firstName: '',
                    lastName: '',
                    dateOfBirth: '',
                    about: '',
                    address: {
                        countryId: null,
                        city: '',
                        street: '',
                        houseNumber: '',
                        zipCode: null,
                        additionalInfo: '',
                    }
                },
                preferredLanguage: '',
                chosenCountry: null,
                countriesSearchString: '',
                error: {
                    role: '',
                    userName: ''
                },
                showPassword: {
                    password: false,
                    repeatPassword: false
                },
                chosenAvatar: null,
                chosenImgUrl: null,
                avatarFileName: '',
                roles: null,
                changePassword: false,
                
                dismissSecs: 10,
                dismissCountDown: 0,
                alert: {
                    dismissSecs: 6,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
                },
                alertDanger: {
                    dismissSecs: 999,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
              }
            }
        },

        computed: {
            isCurrentUserAuthor() {
                return this.$store.getters.currentUserRole === "AUTHOR"
            },
            currentUserRole() {
                return this.$store.getters.currentUserRole
            },
            currentUserId() {
                return this.$store.getters.currentUserId
            },
            filteredCountries() {
                if (!this.countries) return []
                return this.countries.filter(country => {
                    return country[this.countryLocalization].toLowerCase().includes(this.countriesSearchString.toLowerCase())
                }).sort((a, b) => {
                    return sharedUtils.removeAccents(a[this.countryLocalization]).localeCompare(sharedUtils.removeAccents(b[this.countryLocalization]), this.$store.state.preferredLanguage)
                });
            },
            isSidebarVisible() {
                return this.$store.getters.getIsSidebarVisible
            },
            countryLocalization() {
                return this.$store.getters.preferredLanguage == "en" ? "nameEnglish" : "nameCzech"
            }
        },

        methods: {
            refetchUser() {
                this.$apollo.queries.user.refetch({
                    userId: this.userId
                })
            },
            handleEditingPermission() {
                this.userId = this.$route.params.userId;
                if (this.loggedRole != 'ADMIN_SUPERVISOR' && this.userId != this.currentUserId) {
                    this.isEditingAllowed = false
                } else {
                    this.isEditingAllowed = true
                }
            },
            async handleIsAuthorPublishedSwitch(isAuthorPublished) {
                this.isSwitchLoading = true
                await UserService.setIsAuthorPublished(this.user.id, isAuthorPublished).then(() => {
                    this.showAlert(this.$t('alerts.authorPublishChange'))
                    this.isSwitchLoading = false 
                })
                .catch(() => {
                    this.isSwitchLoading = false
                    this.isAuthorPublished = !this.isAuthorPublished 
                    this.showAlertDanger(this.$t('alerts.authorPublishChangeError'))
                })
            },
            async setHasLicenceAgreementSigned() {
                this.loadingLicence = this.$t("loading.loading")
                await UserService.setHasLicenceAgreementSigned(this.user.id).then(async () => {
                    await this.updateOldUser()

                })
                .catch(() => {
                    this.showAlertDanger(this.$t("alerts.unknownError"))
                })
                this.loadingLicence = ''
            },
            blockInvalidChar(e, isPhoneNumber = false) {
                let allowedSymbols = ['1', '2', '3', '4', '5' ,'6' ,'7' ,'8', '9' ,'0', 'Enter']
                if (isPhoneNumber && this.form.phoneNumber.length == 0) allowedSymbols.push('+')

                if (!allowedSymbols.includes(e.key)) {
                    e.preventDefault()
                }
            },
            changePasswordVisibility(inputId) {
                this.showPassword[inputId] = !this.showPassword[inputId]
            },
            validateInputs() {
                let errorCount = 0
                const inputs = [...document.getElementsByTagName("input")]

                if (this.currentUserRole === 'ADMIN_SUPERVISOR' && !this.form.role) {
                    this.error.role = this.$t('inputErrors.required')
                    errorCount += 1
                }
                else if (this.currentUserRole === 'ADMIN_SUPERVISOR'){
                    this.error.role = ''
                }
                inputs.forEach(input => {
                    if (input.required && !input.value) {
                        this.error[input.id] = this.$t("inputErrors.required")
                        errorCount += 1
                    }
                    else {
                        this.error[input.id] = ''
                    }
                })

                if (this.form.userLogin) {
                    const emailRegex = /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@+[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/
                    if (!this.form.userLogin.match(emailRegex)) {
                        errorCount += 1
                        this.error.userLogin = this.$t("inputErrors.incorrectEmailFormat")
                    }
                }

                if (this.form.firstName && !sharedUtils.stringHasOnlyLetters(this.form.firstName)) {
                    errorCount += 1
                    this.error.firstName = this.$t("inputErrors.incorrectFirstNameFormat")
                }
                
                if (this.form.lastName && !sharedUtils.stringHasOnlyLetters(this.form.lastName)) {
                    errorCount += 1
                    this.error.lastName = this.$t("inputErrors.incorrectLastNameFormat")
                }
                
                if (this.form.userName && !sharedUtils.isUserNameValid(this.form.userName)) {
                    errorCount += 1
                    this.error.userName = this.$t("inputErrors.incorrectUserNameFormat")
                }

                if (this.form.password || this.form.repeatPassword) {
                    if (!sharedUtils.passwordHasRequiredSymbols(this.form.password)) {
                        errorCount += 1
                        this.error.password = this.$t("inputErrors.incorrectPasswordFormat")
                    }  else {
                        this.error.password = ''
                    }

                    if (this.form.repeatPassword !== this.form.password) {
                        errorCount += 1
                        this.error.repeatPassword = this.$t("inputErrors.passwordsDoNotMatch")
                    } else {
                        this.error.repeatPassword = ''
                    }

                }

                if (!sharedUtils.stringHasOnlyLettersOrNumbers(this.form.address.zipCode)) {
                    errorCount += 1
                    this.error.zipCode = this.$t("inputErrors.incorrectZipCodeFormat")
                }

                if (errorCount > 0) {
                    this.showAlertDanger(this.$tc("alerts.foundErrorsInForm", errorCount))
                    return false
                }

                return true
            },
            removeAvatar() {
                this.chosenAvatar = null,
                this.avatarFileName = ''
                this.chosenImgUrl = null
            },

            setAvatar(e) {
                const uniqueNameWithoutAccentsAndSpaces = fileUtils.getNormalizedUniqueFileName(e.target.files[0].name)
                this.chosenAvatar = new File([e.target.files[0]], uniqueNameWithoutAccentsAndSpaces, {type: e.target.files[0].type});
            },

            onSubmit: async function() {
                if(!this.validateInputs()) return

                // Create "USER" || "AUTHOR" || "ADMIN" || "ADMIN_SUPERVISOR"
                if(!this.$route.params.userId) {
                    this.loading = this.$t("loading.userCreate")
                    await UserService.createUser(this.form)
                        .then(async ({data}) => {
                            if (this.chosenAvatar) { 
                                await UserService.updateUserAvatar(data.createUser.id, this.chosenAvatar)
                                    .then(() => this.createNewUser())
                                    .catch(err => console.log(err))
                            }
                            else {
                                this.createNewUser()
                            }    
                        })
                        .catch(err => {
                            console.log(err.message)
                            this.handleError(err)
                        });

                // Update "AUTHOR"
                } else if (this.role == 'AUTHOR') {
                    this.loading = this.$t("loading.authorUpdate")
                    await UserService.updateAuthor(this.changePassword, this.userId, this.form)
                        .then( async () => {
                            if(this.chosenAvatar) {
                                await UserService.updateUserAvatar(this.userId, this.chosenAvatar)
                                .then(() => this.updateOldUser())
                            }
                            else if(!this.avatarFileName && this.user.avatarFileName) {
                                await UserService.deleteUserAvatar(this.userId)
                                this.updateOldUser()
                            }
                            else {this.updateOldUser()}
                        }).catch(err =>  {
                            console.log(err.message)
                            this.handleError(err)
                        });

                // Update "USER", "ADMIN", "ADMIN_SUPERVISOR"
                } else {
                    this.loading = this.$t("loading.userUpdate")
                    await UserService.updateUser(this.changePassword, this.userId, this.form)
                        .then( async () => {
                            if(this.chosenAvatar) {
                                await UserService.updateUserAvatar(this.userId, this.chosenAvatar)
                                .then(() => this.updateOldUser())
                            }
                            else if(!this.avatarFileName && this.user.avatarFileName) {
                                await UserService.deleteUserAvatar(this.userId)
                                this.updateOldUser()
                            }
                            else {this.updateOldUser()}
                        }).catch(err =>  {
                            console.log(err.message)
                            this.handleError(err)
                        });
                }

                if (this.preferredLanguageForCurrentUser && this.preferredLanguage != this.preferredLanguageForCurrentUser) {
                    await UserService.setPreferredLanguageForCurrentUser(this.preferredLanguage)
                    .then(() => {
                        localStorage.setItem('preferredLanguage', this.preferredLanguage)
                    })
                    .catch(err => {
                        console.log(err)
                        this.showAlertDanger(this.$t('alerts.preferredLanguageChangeError'))
                    })
                    location.reload()
                }
            },

            handleError(error) {
                if (error.message.includes('Password should contain')) this.error.password = this.$t("inputErrors.incorrectPasswordFormat")
                else if (error.message.includes('Username already used')) this.error.userName = this.$t("inputErrors.userNameAlreadyUsed", [this.form.userName])
                else if (error.message.includes('Login already used')) this.error.userLogin = this.$t("inputErrors.loginAlreadyUsed", [this.form.userLogin])
                else if (error.message.includes('Invalid email address')) this.showAlertDanger(this.$t("alerts.invalidEmailAddress"))
                else this.showAlertDanger(this.$t("alerts.userUpdateError"))
                this.loading = ''
            },

            onBlockConfirmed: async function() {
                this.loadingBlock = this.$t("loading.userBlock")
                await UserService.blockUser(this.userId)
                    .then(() => {
                        this.loadingBlock = ''
                        this.$bvModal.hide('user-block-modal')
                        this.refetchUser()
                        this.showAlert(this.$t('alerts.userBlocked'))
                    })
                    .catch(err => {
                        this.loadingBlock = ''
                        console.log(err)
                        this.showAlertDanger(this.$t('alerts.userBlockError'))
                    })
            },
                
            onUnblockConfirmed: async function() {
            this.loadingUnblock = this.$t("loading.userUnblock")
            await UserService.unblockUser(this.userId)
                .then(() => {
                    this.loadingUnblock = ''
                    this.$bvModal.hide('user-unblock-modal')
                    this.refetchUser()
                    this.showAlert(this.$t('alerts.userUnblocked'))
                })
                .catch(err => {
                    this.loadingUnblock = ''
                    console.log(err)
                    this.showAlertDanger(this.$t('alerts.userUnblockError'))
                })
            },

            onDeleteConfirmed: async function() {
                this.loadingDelete = this.$t("loading.userDelete")
                if (this.userId === this.loggedId) {
                    // this.$store.dispatch(AUTH_LOGOUT)
                    await UserService.lockUser(this.userId)
                    .catch(err => (console.log(err)))
                    this.$store.commit('setCurrentUserId', null)
                    localStorage.removeItem("user-token");
                    localStorage.removeItem("id");
                    localStorage.removeItem("username");
                    localStorage.removeItem("role");
                    this.refetchUser()
                    this.$router.push('/login');
                }
                else if (this.userId != null && this.userId != this.loggedId && this.currentUserRole != "AUTHOR") {
                    await UserService.lockUser(this.userId)
                        .then(() => {
                            this.loadingDelete = ''
                            this.$bvModal.hide('user-delete-modal')
                            this.refetchUser()
                            this.showAlert(this.$t('alerts.userAnonymized'))
                        })
                }
            },

            resetToDefault: function() {
                let formattedDateOfBirth = null
                if (this.user) {
                    if (this.user.dateOfBirth) {
                        const inputDate = new Date(this.user.dateOfBirth);
                        formattedDateOfBirth = inputDate.toISOString().split('T')[0];
                    }
                    if (this.user.licenceAgreementSignedAt) {
                        const moment = require('moment');
                        const formattedDate = moment(this.user.licenceAgreementSignedAt).format('DD. M. YYYY HH:mm:ss');
    
                        this.licenceAgreementSignedAt = formattedDate
                    }

                    this.isAuthorPublished = this.user.isAuthorPublished ? true : false
                    this.form = {
                        userName: this.user.userName,
                        userLogin: this.user.userLogin,
                        phoneNumber: this.user.phoneNumber,
                        role: this.user.role.code,
                        password: '',
                        repeatPassword: '',
                        firstName: this.user.firstName,
                        lastName: this.user.lastName,
                        dateOfBirth: formattedDateOfBirth || null,
                        about: this.user.about,
                        address: {
                            countryId: this.user.address?.country?.id,
                            city: this.user.address?.city,
                            street: this.user.address?.street,
                            houseNumber: this.user.address?.houseNumber,
                            zipCode: this.user.address?.zipCode,
                            additionalInfo: this.user.address?.additionalInfo
                        }
                    }
                    if (this.user.address && this.user.address.country != null) {
                        const country = this.user.address?.country
                        this.chosenCountry = country;
                    }
                    this.avatarFileName = this.user.avatarFileName
                }
            },

            async createNewUser() {
                this.initialLoading = false
                this.$emit("showAlert", this.$t('alerts.userCreated'))
                await this.$router.push({name: 'adminUsers'});
            },

            async updateOldUser(message) {
                this.showAlert(this.$t('alerts.userUpdated'));
                this.refetchUser()
            },
            showAlert(notification) {
                this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
                this.loading = ''
                this.alert.dismissCountDown = this.alert.dismissSecs
                this.alert.notification = notification;
            },

            showAlertDanger(notification) {
                this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
                this.loading = ''
                this.alertDanger.dismissCountDown = this.alertDanger.dismissSecs
                this.alertDanger.notification = notification
            },
            countDownChanged(dismissCountDown) {
                this.alert.dismissCountDown = dismissCountDown
            },

            countDownChangedDanger(dismissCountDown) {
              this.alertDanger.dismissCountDown = dismissCountDown
            },
        },

        apollo: {
            user: {
                query: function() {
                    return AUTHOR_BY_ID
                },

                variables: function() {
                    return {
                        userId: this.userId,
                    }
                },

                result: function(resp) {
                    this.role = resp.data.user.role.code
                },

                skip: function () {
                    return this.userId == null;
                },
            },
            roles: {
                query : ROLES,
            },

            countries: {
                query: COUNTRIES
            },
            preferredLanguageForCurrentUser: {
                query: USER_PREFERRED_LANGUAGE,
                skip: function() {
                    return this.userId != this.currentUserId
                },
                result({data}) {
                    this.preferredLanguage = data.preferredLanguageForCurrentUser
                }
            }
            
        },

        watch: {
            isAuthorPublished(nV, oV) {
                if (nV != null && oV != null) {
                    this.handleIsAuthorPublishedSwitch(nV)
                }
            },
            '$route.params'() {
                this.handleEditingPermission()
                this.refetchUser()
            },
            user: function (nV) {
                if (this.initialLoading) this.initialLoading = false
                this.resetToDefault();
            },
            chosenAvatar(newValue) {
                if (newValue) {
                    this.chosenImgUrl = URL.createObjectURL(newValue)
                }
            },
            'form.address.countryId'(nV) {
                this.countriesSearchString = ''
                if (this.countries) {
                    this.chosenCountry = this.countries.find(country => country.id == nV);
                }
            },
            'form.password'(nV) {
                this.changePassword = nV ? true : false
            },
        },

        created() {
            this.handleEditingPermission()
        },

    }
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

.user-state-alert {
    background-color: $tr-black;
    color: $tr-white;
    padding: 5px 10px;
    font-size: 20px;
    text-align: center;
}

.licence-signed-container {
    width: 100%;
    max-width: 330px;
}

.dropdown-input {
    height: 60px;
    width: 100%;
    padding-inline: 20px;
    border: none;
    border-bottom: solid 2px $tr-gray;
    background-color: $tr-white;
}

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    input[type=number] {
    -moz-appearance: textfield;
    }

    .section-title {
        margin-bottom: 30px;
        font-size: 25px;
        margin-top: 20px;
    }

    .form-group {
        display: flex;
        gap: 40px;
        margin-bottom: 30px;
    }
    .admin-page-loading {
        transform: translate(-50%, -50%);
        position: fixed;
        top: 50%;
        left: calc(50% + 140px);
        font-size: 20px;
    }
    .form {
        gap: 0;

        &.no-interaction .x, &.no-interaction .img-container > div > div {
            display: none !important;
        }
        &.no-interaction .img-container {
            pointer-events: none;
        }
        .img-container {
            display:flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            max-width: 330px;
            aspect-ratio: 1;
            border: dashed 3px $tr-light-gray;
            position: relative;

            &:hover {
                .plus {
                    background-color: $tr-dark-gray;
                }
            }
            .avatar-image {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
            &.noborder {
                border: none;
            }
            input {
                display: none;
            }
            .photo {
                width: 100px;
                height: 100px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                border-radius: 50%;
            }
            img {
                width: 100%;
                height: 100%;
            }
            label {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                opacity: 0;
                cursor: pointer;
            }
        }

        .fixed-buttons {
            display: flex;
            justify-content: center;
            max-width: calc(100% - 300px);
            width: 100%;
            bottom: 0;
            left: 300px;
            background-color: $tr-white;
            border-top: 1px solid $tr-light-gray;;
            position: fixed;
            z-index: 1030;
            transition: 0.3s ease-in-out;
            transition-property: left, max-width;

            &.full-width {
            max-width: 100%;
            left: 0
            }

            & > div {
            max-width: 1090px;
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 40px;
            padding: 10px 40px;

            & > div {
                max-width: 330px;
                width: 100%;
            }
            }

            &.manage-localization {
            & > div {
                justify-content: unset;

                & > div {
                margin-left: calc(50% + 15px);
                }
            }
            }
        }
    }
    .red-border {
        display: block !important;
    }
</style>